import React, { useEffect, useState, useMemo, useRef } from 'react'
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete'
import LoadingBar from 'react-top-loading-bar'

import MapContainer from './MapContainer'
import SummaryContainer from './SummaryContainer'

import Services from '../services.js'
import ItemCategories from '../enums/ItemCategories'
import CouponAutomations from '../enums/CouponAutomations'
import CouponTypologies from '../enums/CouponTypologies'
import OrderConstants from '../enums/OrderConstants'

const moment = require('moment')
const prettyHour = (hour) => {
    return hour.split('T')[1].substr(0, 5)
}

const separator = ", dopo cottura: "


const Main = (props) => {
    // INSERIMENTO ANAGRAFICA
    const [phone, setPhone] = useState("")
    const [nome, setNome] = useState("")
    const [hour, setHour] = useState("")
    const [takeAway, setTakeAway] = useState(false)
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [platform, setPlatform] = useState(OrderConstants.NO_PLATFORM)
    const [address, setAddress] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [notes, setNotes] = useState("")
    const [distance, setDistance] = useState(null)
    const [payment, setPayment] = useState("Contanti")

    const onChangeDate = (e) => {
        const date = e.target.value
        if (date) {
            const newDate = moment(date).format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const onChangeHour = (e) => {
        const hour = e.target.value
        if (hour === "00:00") {  //subito
            setHour(moment().format('HH:mm'))
        } else {
            setHour(hour)
        }
    }
    const onChangePlatform = (e) => {
        const platform = e.target.value
        setPlatform(platform)
    }
    const onChangeAddress = (e, description = "") => {
        // mostra o nasconde le opzioni in base all'input
        currentFocusAddress.current = -1
        const address = document.getElementById("indirizzo").value.replaceAll('/', '')

        setAddress(address)
        setValue(address, !description)
    }
    const onChangeNotes = (e) => {
        const notes = e.target.value
        setNotes(notes)
    }
    const onChangePayment = (e) => {
        const payment = e.target.value
        setPayment(payment)
    }
    const calculateDistance = () => {
        if (address && props.settings?.general) {
            const source = props.settings.general.address
            let destination = address
            console.log(data)
            if (data.length === 1) {
                destination = data[0].description
                setAddress(data[0].description)
            }

            Services.calculateDistance(source, destination)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.rows[0].elements[0].status === 'OK') {
                        setDistance({
                            space: response.data.rows[0].elements[0].distance.text,
                            time: response.data.rows[0].elements[0].duration.text
                        })
                    } else {
                        setDistance(null)
                    }
                })
                .catch((error) => {
                    console.log(error.response ? error.response.data.error : "Errore Google Places API.")
                })
        }
    }

    // TABELLA VERIFICA ORARI CON DRIVERS E ORARI DINAMICI
    const [orders, setOrders] = useState([])
    const [hours, setHours] = useState([])
    const [currentCoordinates, setCurrentCoordinates] = useState(null)
    const [triggerOrdersUpdate, setTriggerOrdersUpdate] = useState(0)

    async function retrieveOrders(todayOrders = null) {
        // recupero gli ordini per poter riempire la tabella verifica orari
        if (todayOrders) {
            setOrders(todayOrders)
        } else if (props.user?.username && date) {
            await Services.getOrders(props.user.username, date)
                .then((response) => {
                    console.log(response.data.orders)
                    setOrders(response.data.orders)
                })
                .catch((error) => {
                    console.log(error)
                    setOrders([])
                })
        }
    }
    async function retrieveFreeDriversSlots() {
        // recupero il numero di slot disponibili per ogni fascia oraria (solo per il giorno di date)
        if (props.user?.username && date) {
            await Services.getFreeDriversSlots(props.user.username, date)
                .then((response) => {
                    const now = moment().toObject()
                    let free_slots = []
                    for (let h in response.data.slots) {
                        if (date !== moment().format('YYYY-MM-DD') ||
                            (date === moment().format('YYYY-MM-DD') && h >= `${String(now.hours).padStart(2, "0")}:${String(now.minutes).padStart(2, "0")}`)) {

                            free_slots.push([h, response.data.slots[h]])
                        }
                    }
                    setHours(free_slots)
                })
                .catch((error) => {
                    console.log(error)
                    setHours([])
                })
        }
    }
    const retrieveFunction = (todayOrders = null) => {
        retrieveOrders(todayOrders).then(() => {
            console.log("recupero ordini")

            retrieveFreeDriversSlots().then(() => {
                console.log("recupero slot liberi")
            }, (err) => console.log(err))
        }, (err) => console.log(err))
    }

    useEffect(() => {
        retrieveFunction()

        if (date === moment().format('YYYY-MM-DD')) {  // se la data è oggi devo aggiornare dinamicamente
            const interval = setInterval(() => {
                retrieveFunction(props.orders.current)
            }, 15 * 1000)

            return () => clearInterval(interval)
        }
    }, [date, triggerOrdersUpdate])  // quando cambio la data devo contestualizzare gli slot disponibili e la tabella verifica orari

    const getOrdersByHour = (hours, start, end) => {
        let ordersThisHour = []
        if (start === 0 && hours[0] === props.settings?.delivery?.startHour) {
            ordersThisHour = orders.filter((value) => {
                return prettyHour(value.hour) < hours[end]
            })
        } else if (end === hours.length) {
            ordersThisHour = orders.filter((value) => {
                return prettyHour(value.hour) === hours[start]
            })
        } else {
            ordersThisHour = orders.filter((value) => {
                return prettyHour(value.hour) >= hours[start] && prettyHour(value.hour) < hours[end]
            })
        }
        return ordersThisHour
    }
    const defaultVerify = (max, defaultHours) => {
        let render = []
        if (props.settings?.slots && props.settings?.slots[moment(date).day()]) {
            for (let i = 0; i < defaultHours.length; i++) {
                const h = defaultHours[i]

                const ordersThisHour = getOrdersByHour(defaultHours, i, i+1)
                const deliveriesThisHour = ordersThisHour.reduce((total, currentValue) => {
                    if (currentValue.mode === OrderConstants.MODE_CONSEGNA) {
                        return total + 1
                    } else {
                        return total
                    }
                }, 0)
                const takeAwayThisHour = ordersThisHour.reduce((total, currentValue) => {
                    if (currentValue.mode === OrderConstants.MODE_RITIRO) {
                        const plates = currentValue.items.reduce((total, currentValue) => {
                            if (currentValue.category === ItemCategories.PIZZE) {
                                return total + currentValue.quantity
                            } else {
                                return total
                            }
                        }, 0)

                        return total + plates
                    } else {
                        return total
                    }
                }, 0)
                render.push(
                    <tr key={`${h}-ordini`}>
                        <th scope="row">{h} ({deliveriesThisHour}/{props.settings?.slots[moment(date).day()][h]})</th>
                        {[...Array(max)].map((c, i) => {
                            const i_thOrder = ordersThisHour.filter((value) => {
                                return value.mode === OrderConstants.MODE_CONSEGNA
                            })[i]
                            if (i_thOrder) {  //nella colonna c'è l'ordine i-esimo (si può anche occupare uno slot non disponibile)
                                return (
                                    <td key={`${h}-ordine-${i}`}>
                                        <div className="card">
                                            <div className="card-body">
                                                <span><b>Ordine:</b> #{i_thOrder.seq_value}</span><br />
                                                <span><b>Indirizzo:</b> {i_thOrder.reg?.address}</span><br />
                                                <span><b>Pizze:</b> {i_thOrder.items.reduce((total, currentValue) => {
                                                        if (currentValue.category === ItemCategories.PIZZE) {
                                                            return total + currentValue.quantity
                                                        } else {
                                                            return total
                                                        }
                                                    }, 0)}
                                                </span><br />
                                                <span><b>Distanza:</b> {i_thOrder.reg?.distance?.space} - {i_thOrder.reg?.distance?.time}</span>
                                            </div>
                                        </div>
                                    </td>
                                )
                            } else if (i >= props.settings?.slots[moment(date).day()][h]) {  //la colonna corrisponde ad uno slot non disponibile
                                return (
                                    <td key={`${h}-ordine-${i}`}></td>
                                )
                            } else {  //la colonna corrisponde ad uno slot disponibile
                                return (
                                    <td key={`${h}-ordine-${i}`}>
                                        <div className="card">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </td>
                                )
                            }
                        })}
                        <td>
                            <div className="card">
                                <div className="card-body">
                                    {takeAwayThisHour > 0 && <span><b>Pizze:</b> {takeAwayThisHour}</span>}
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }
        }
        return render
    }
    const renderVerify = () => {
        let render = []
        if (props.settings?.slots && props.settings?.slots[moment(date).day()]) {
            const now = moment().toObject()
            let defaultHours = []
            for (let h in props.settings?.slots[moment(date).day()]) {
                if (date !== moment().format('YYYY-MM-DD') ||
                    (date === moment().format('YYYY-MM-DD') && h >= `${String(now.hours).padStart(2, "0")}:${String(now.minutes).padStart(2, "0")}`)) {

                    defaultHours.push(h)
                }
            }
            
            let max = 0
            // primo calcolo: massimo numero di consegne in base agli slot
            for (let h in props.settings?.slots[moment(date).day()]) {
                if (props.settings?.slots[moment(date).day()][h] > max) {
                    max = props.settings?.slots[moment(date).day()][h]
                }
            }
            // secondo calcolo: massimo numero di consegne in base agli ordini effettuati (per visualizzare consegne extra)
            for (let i = 0; i < defaultHours.length; i++) {
                const ordersThisHour = getOrdersByHour(defaultHours, i, i+1)
                const deliveriesThisHour = ordersThisHour.reduce((total, currentValue) => {
                    if (currentValue.mode === OrderConstants.MODE_CONSEGNA) {
                        return total + 1
                    } else {
                        return total
                    }
                }, 0)
                if (deliveriesThisHour > max) {
                    max = deliveriesThisHour
                }
            }
            //console.log(max)

            render = (
                <table className="table table-striped text-dark">
                    <thead>
                        <tr>
                            <th scope="col">Orario</th>
                            {[...Array(max)].map((c, i) => {
                                return (
                                    <th scope="col" key={`consegna-${i+1}`}>CONSEGNA #{i+1}</th>
                                )
                            })}
                            <th scope="col">RITIRO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultVerify(max, defaultHours)}
                    </tbody>
                </table>
            )
        }
        return render
    }

    // COMPILAZIONE AUTOMATICA ANAGRAFICA
    const memoizedClients = useMemo(() => props.clients ? props.clients : [], [props.clients])
    const [filteredClients, setFilteredClients] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)

    const currentFocusPhone = useRef(-1)
    const onFocusPhone = () => {
        if (document.getElementById("listaclienti"))
            document.getElementById("listaclienti").style.display = "block"
        if (document.getElementById("phone"))
            document.getElementById("phone").style.borderRadius = ".35rem 0 0 0"
    }
    const onBlurPhone = () => {
        if (document.getElementById("listaclienti"))
            document.getElementById("listaclienti").style.display = "none"
        if (document.getElementById("phone"))
            document.getElementById("phone").style.borderRadius = ".35rem 0 0 .35rem"
    }
    const onKeyDownPhone = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("listaclienti")) {
            if (e.keyCode === 40) {
                currentFocusPhone.current += 1
                addActivePhone(document.getElementById("listaclienti").options)
            } else if (e.keyCode === 38) {
                currentFocusPhone.current -= 1
                addActivePhone(document.getElementById("listaclienti").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocusPhone.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("listaclienti").options) {
                        document.getElementById("listaclienti").options[currentFocusPhone.current]?.click()
                        onBlurPhone()
                        removeActivePhone(document.getElementById("listaclienti").options)
                        currentFocusPhone.current = -1
                    }
                }
            }
        }
    }
    function addActivePhone(x) {
        if (!x) return false
        removeActivePhone(x)
        if (currentFocusPhone.current >= x.length) currentFocusPhone.current = 0
        if (currentFocusPhone.current < 0) currentFocusPhone.current = (x.length - 1)
        const id = x[currentFocusPhone.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActivePhone(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }
    const onChangePhone = () => {
        currentFocusPhone.current = -1
        const clientData = document.getElementById("phone").value.split(' \u2014 ')
        if (clientData.length === 3) {  // ho selezionato dalla datalist
            const filtered = memoizedClients.filter((c) => {
                return (c.phone === clientData[0] && c.name === clientData[1] && c.address === clientData[2])
            })

            if (filtered.length === 1) {
                setSelectedClient(filtered[0])
                setPhone(clientData[0])
            } else {
                setSelectedClient(null)
            }
        } else {  // sto scrivendo
            onFocusPhone()
            setPhone(clientData[0])
        }
    }

    const currentFocusNome = useRef(-1)
    const onFocusNome = () => {
        if (document.getElementById("listaclienti2"))
            document.getElementById("listaclienti2").style.display = "block"
        if (document.getElementById("nome"))
            document.getElementById("nome").style.borderRadius = ".35rem .35rem 0 0"
    }
    const onBlurNome = () => {
        if (document.getElementById("listaclienti2"))
            document.getElementById("listaclienti2").style.display = "none"
        if (document.getElementById("nome"))
            document.getElementById("nome").style.borderRadius = ".35rem"
    }
    const onKeyDownNome = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("listaclienti2")) {
            if (e.keyCode === 40) {
                currentFocusNome.current += 1
                addActiveNome(document.getElementById("listaclienti2").options)
            } else if (e.keyCode === 38) {
                currentFocusNome.current -= 1
                addActiveNome(document.getElementById("listaclienti2").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocusNome.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("listaclienti2").options) {
                        document.getElementById("listaclienti2").options[currentFocusNome.current]?.click()
                        onBlurNome()
                        removeActiveNome(document.getElementById("listaclienti2").options)
                        currentFocusNome.current = -1
                    }
                }
            }
        }
    }
    function addActiveNome(x) {
        if (!x) return false
        removeActiveNome(x)
        if (currentFocusNome.current >= x.length) currentFocusNome.current = 0
        if (currentFocusNome.current < 0) currentFocusNome.current = (x.length - 1)
        const id = x[currentFocusNome.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActiveNome(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }
    const onChangeNome = () => {
        currentFocusNome.current = -1
        const clientData = document.getElementById("nome").value.split(' \u2014 ')
        if (clientData.length === 3) {  // ho selezionato dalla datalist
            const filtered = memoizedClients.filter((c) => {
                return (c.phone === clientData[0] && c.name === clientData[1] && c.address === clientData[2])
            })

            if (filtered.length === 1) {
                setSelectedClient(filtered[0])
            } else {
                setSelectedClient(null)
            }
        } else {  // sto scrivendo
            setNome(clientData[0])
        }
    }

    useEffect(() => {
        if (!props.clients) {
            props.setTriggerClientsUpdate(Date.now())
        }
    }, [])

    useEffect(() => {
        if (phone) {
            const filtered = memoizedClients.filter((c) => {
                return c.phone.includes(phone)
            })
            setFilteredClients(filtered.slice(0, 10))
        }
    }, [phone])

    useEffect(() => {
        if (nome) {
            const filtered = memoizedClients.filter((c) => {
                const nomeLower = nome.toLowerCase()
                return c.name.toLowerCase().includes(nomeLower)
            })
            setFilteredClients(filtered.slice(0, 10))
        }
    }, [nome])

    useEffect(() => {
        if (selectedClient) {
            if (phone === "" || selectedClient.phone.startsWith(phone)) {
                setPhone(selectedClient.phone)
            }
            setNome(selectedClient.name)
            setAddress(selectedClient.address)
            setNotes(selectedClient.notes)
            setDistance(selectedClient.distance)
        }
    }, [selectedClient])

    const synchronizePhone = () => {
        if (props.user?.username && memoizedClients.length > 0) {  // sincronizzo solo se ho già memorizzato i clienti
            Services.getRingingPhone(props.user.username)
                .then((response) => {
                    if (response.data.ringings) {
                        setPhone(response.data.ringings.phone)

                        const filtered = memoizedClients.filter((c) => {
                            return c.phone.includes(response.data.ringings.phone)
                        })
                        if (filtered.length === 1) {
                            setSelectedClient(filtered[0])
                        } else {
                            // pulisco i campi e faccio scegliere all'utente
                            setNome("")
                            setAddress("")
                            setNotes("")
                            setDistance(null)
                            setSelectedClient(null)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        }
    }

    // INSERIMENTO ORDINE
    const memoizedMenu = useMemo(() => props.menu, [props.menu])
    const [plates, setPlates] = useState([])
    const [order, setOrder] = useState([])
    const [total, setTotal] = useState(0.0)
    const [supplement, setSupplement] = useState(0.0)
    const [finalTotal, setFinalTotal] = useState(0.0)
    const [progress, setProgress] = useState(0)
    const [coupon, setCoupon] = useState(null)
    const [couponName, setCouponName] = useState("")
    const [showWarning, setShowWarning] = useState(false)
    const [bodyWarning, setBodyWarning] = useState([])

    const currentFocusCoupon = useRef(-1)
    const onFocusCoupon = () => {
        if (document.getElementById("listacoupon"))
            document.getElementById("listacoupon").style.display = "block"
        if (document.getElementById("coupon"))
            document.getElementById("coupon").style.borderRadius = ".35rem .35rem 0 0"
    }
    const onBlurCoupon = () => {
        if (document.getElementById("listacoupon"))
            document.getElementById("listacoupon").style.display = "none"
        if (document.getElementById("coupon"))
            document.getElementById("coupon").style.borderRadius = ".35rem"
    }
    const onKeyDownCoupon = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("listacoupon")) {
            if (e.keyCode === 40) {
                currentFocusCoupon.current += 1
                addActiveCoupon(document.getElementById("listacoupon").options)
            } else if (e.keyCode === 38) {
                currentFocusCoupon.current -= 1
                addActiveCoupon(document.getElementById("listacoupon").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocusCoupon.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("listacoupon").options) {
                        document.getElementById("listacoupon").options[currentFocusCoupon.current]?.click()
                        onBlurCoupon()
                        removeActiveCoupon(document.getElementById("listacoupon").options)
                        currentFocusCoupon.current = -1
                    }
                }
            }
        }
    }
    function addActiveCoupon(x) {
        if (!x) return false
        removeActiveCoupon(x)
        if (currentFocusCoupon.current >= x.length) currentFocusCoupon.current = 0
        if (currentFocusCoupon.current < 0) currentFocusCoupon.current = (x.length - 1)
        const id = x[currentFocusCoupon.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActiveCoupon(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }
    const onChangeCoupon = () => {
        currentFocusCoupon.current = -1
        const coupon = document.getElementById("coupon").value

        setCouponName(coupon)
        const couponObj = props.settings?.coupons?.filter((c) => c.name.toLowerCase() === coupon.toLowerCase())
        setCoupon(couponObj[0] ?? null)
    }

    const newPrice = (o) => {
        let original = o.price

        for (let j in o.add) {
            original += o.add[j].price
        }
        for (let j in o.rem) {
            original -= o.rem[j].price
        }

        return original
    }

    useEffect(() => {
        console.log(order)

        let total = 0.0

        let coupon = []  // eventuali coupon (da applicare alla fine, ma comunque prima del supplemento)
        for (let i in order) {
            if (Object.values(CouponTypologies).includes(order[i].category)) {
                if (order[i].category === CouponTypologies.SCONTO) {
                    coupon.push(i)  // lo sconto percentuale sul totale (che comprende le spese di consegna) deve essere applicato per ultimo (così funziona anche se cambia l'ordine)
                } else {
                    coupon = [i, ...coupon]
                }
                continue
            }
            total += order[i].finalPrice * order[i].quantity
        }

        const newOrder = JSON.parse(JSON.stringify(order))  // deep copy
        for (let j of coupon) {  // posizioni dei coupon all'interno di items
            if (order[j].category === CouponTypologies.DECREMENTO && order[j].price > 0) {
                // devo controllare che il totale sia >= del decremento (altrimenti riporto il prezzo finale del coupon a 0)
                if (total >= order[j].price) {
                    total -= order[j].price
                    newOrder[j].finalPrice = -order[j].price
                } else {
                    newOrder[j].finalPrice = 0
                }
            } else if (order[j].category === CouponTypologies.SCONTO && order[j].price > 0) {
                const price = Number((total * order[j].price/100).toFixed(2))
                total -= price
                newOrder[j].finalPrice = -price
            } else if (order[j].category.includes("OMAGGIO")) {
                // cerco un piatto da togliere (quello che ha il prezzo minore) e se non lo trovo riporto il prezzo finale del coupon a 0
                const plates = order.filter((item) => item.category.toUpperCase() === order[j].category.split(" ")[1])
                plates.sort((a, b) => a.finalPrice - b.finalPrice)
                if (plates.length > 0) {
                    total -= plates[0].finalPrice
                    newOrder[j].finalPrice = -plates[0].finalPrice
                } else {
                    newOrder[j].finalPrice = 0
                }
            }
        }
        if (JSON.stringify(order) !== JSON.stringify(newOrder)) {
            // devo triggerare ancora per vedere subito i prezzi finali dei coupon nel riepilogo
            // ovviamente questo verrà fatto solo se il prezzo finale stesso cambia (per evitare cicli infiniti)
            setOrder(newOrder)
        }

        setTotal(total.toFixed(2))  // totale piatti inseriti (comprese eventuali spese di consegna) con coupon applicati
        setFinalTotal((total + supplement).toFixed(2))  // totale da visualizzare = totale + eventuale supplemento
        // il supplemento viene settato a mano oppure viene ricavato da un ordine che ha un totale maggiore del totale sui piatti (viene sempre settato insieme a finalTotal)
    }, [order])

    useEffect(() => {
        if (coupon) {
            // controllo se il coupon non può essere applicato, ossia se nell'anagrafica corrispondente c'è una data di utilizzo minore di oggi
            if (phone) {
                Services.getPromoInfo(props.user.username, phone)
                    .then((response) => {
                        let today = new Date()
                        today = today.toJSON().substring(0, 19)
                        let couponDate = today
                        if (response.data.promo && response.data.promo[coupon.name]) {  // esistenza del campo promo && esistenza del coupon come chiave di promo (diverso da "" compreso)
                            couponDate = response.data.promo[coupon.name].substring(0, 19)
                        }
                        if (couponDate < today) {
                            alert("Attenzione! Questo coupon è già stato utilizzato da "+phone+" il giorno "+moment(couponDate+"+00").locale('it').format('LLLL'))
                            // setOrder(prevOrder)
                        } else if ((coupon.automation === CouponTypologies.APP || coupon.automation === CouponTypologies.RECALL)
                            && (response.data.promo && Object.keys(response.data.promo).includes(coupon.name))) {
                            // se il coupon è di tipo automation, allora può esistere il coupon come chiave di promo (anche se vale "") quindi in caso informo
                            alert("Attenzione! Questo coupon è stato assegnato a "+phone+" per "+CouponAutomations[coupon.automation])
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            const couponEntry = {
                name: coupon.name,
                price: Number(coupon.value),  // uso interno
                quantity: 1,  // ininfluente
                add: [],
                rem: [],
                finalPrice: 0.0,  // verrà settato dinamicamente in modo che non vada a influenzare il supplemento
                category: coupon.typology,
                ingredients: [],
                notes: ""
            }

            setOrder([...order, couponEntry])
            setCoupon(null)
            onBlurCoupon()
            setCouponName("")
        }
    }, [coupon])

    useEffect(() => {
        let newOrder = JSON.parse(JSON.stringify(order))  // deep copy

        const speseConsegnaIndex = newOrder.findIndex((i) => i.category === OrderConstants.DELIVERY_COSTS)  // -1 se non c'è
        if (speseConsegnaIndex >= 0 && takeAway) {  // se c'è ma ho messo Ritiro allora la tolgo
            newOrder = order.filter((i) => i.category !== OrderConstants.DELIVERY_COSTS)
        } else if (speseConsegnaIndex < 0 && !takeAway && props.settings?.delivery?.cons !== undefined) {  // se non c'è, ho messo Consegna e cons esiste allora la aggiungo
            const speseConsegnaEntry = {
                name: OrderConstants.DELIVERY_COSTS,
                price: props.settings.delivery.cons,
                quantity: 1,
                add: [],
                rem: [],
                finalPrice: props.settings.delivery.cons,
                category: OrderConstants.DELIVERY_COSTS,
                ingredients: [],
                notes: ""
            }
            newOrder = [...order, speseConsegnaEntry]
        }

        // Azzeramento spese di consegna
        if (speseConsegnaIndex >= 0 && !takeAway && props.settings?.delivery?.cons !== undefined && props.settings?.delivery?.azz > 0) {
            if ((finalTotal - newOrder[speseConsegnaIndex].finalPrice) >= props.settings.delivery.azz) {
                newOrder[speseConsegnaIndex].finalPrice = 0
            } else {
                newOrder[speseConsegnaIndex].finalPrice = props.settings.delivery.cons
            }
        }

        if (JSON.stringify(order) !== JSON.stringify(newOrder)) {
            // devo triggerare ancora per vedere subito gli ultimi aggiornamenti nel riepilogo
            // ovviamente questo verrà fatto solo se l'ordine cambia effettivamente (per evitare cicli infiniti)
            setOrder(newOrder)
        }
    }, [props.settings, order, takeAway, finalTotal])

    useEffect(() => {
        const bw = []

        if (!takeAway) {
            // Warning ordine minimo
            if (props.settings?.delivery?.minimo > 0 && finalTotal < props.settings?.delivery?.minimo) {
                bw.push("Il prezzo minimo d'ordine impostato è di € " + props.settings.delivery.minimo.toFixed(2))
            }

            // Warning distanza massima di consegna
            if (props.settings?.delivery?.dist > 0 && distance?.space.split(" ")[0] > props.settings?.delivery?.dist) {
                bw.push("La distanza massima di consegna impostata è di " + props.settings.delivery.dist + " km")
            }
        }

        setBodyWarning(bw)
        if (bw.length > 0) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
        }
    }, [finalTotal, distance, takeAway])

    const currentFocus = useRef(-1)
    const onFocusEntry = () => {
        if (document.getElementById("listapiatti"))
            document.getElementById("listapiatti").style.display = "block"
        if (document.getElementById("piatto"))
            document.getElementById("piatto").style.borderRadius = ".35rem .35rem 0 0"
    }
    const onBlurEntry = () => {
        if (document.getElementById("listapiatti"))
            document.getElementById("listapiatti").style.display = "none"
        if (document.getElementById("piatto"))
            document.getElementById("piatto").style.borderRadius = ".35rem"
    }
    const onChangeEntry = () => {
        // mostra o nasconde le opzioni in base all'input
        currentFocus.current = -1
        const text = document.getElementById("piatto").value.toLowerCase()

        const plates = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()

            if (m.category !== ItemCategories.INGREDIENTI) {  // considero solo le pizze/bibite e non gli ingredienti omonimi
                return nameLower.includes(text)
            } else {
                return false
            }
        })
        setPlates(plates)
        if (plates.length === 1) {
            addEntry(text)
        }
    }
    const onKeyDownEntry = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("listapiatti")) {
            if (e.keyCode === 40) {
                currentFocus.current += 1
                addActive(document.getElementById("listapiatti").options)
            } else if (e.keyCode === 38) {
                currentFocus.current -= 1
                addActive(document.getElementById("listapiatti").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocus.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("listapiatti").options) {
                        document.getElementById("listapiatti").options[currentFocus.current]?.click()
                        currentFocus.current = -1  // per permettere al successivo invio di fare il focus sull'ultima pizza inserita
                    }
                } else {
                    document.getElementById("modificaRef-0")?.focus()  // focus sull'ultima pizza inserita per modifica veloce (è sempre la prima della lista)
                    document.getElementById("modificaRef-0")?.click()
                }
            }
        }
    }
    function addActive(x) {
        if (!x) return false
        removeActive(x)
        if (currentFocus.current >= x.length) currentFocus.current = 0
        if (currentFocus.current < 0) currentFocus.current = (x.length - 1)
        const id = x[currentFocus.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActive(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }
    const addEntry = (entry) => {
        //find pizza tramite nome
        const newEntries = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()
            if (m.category !== ItemCategories.INGREDIENTI) {  // considero solo le pizze/bibite e non gli ingredienti omonimi
                return nameLower === entry
            } else {
                return false
            }
        })

        if (newEntries.length > 0) {
            const desc = newEntries[0].description.split(separator)
            let descIn = desc[0].split(/,/)
            descIn.forEach((item, index, arr) => arr[index] = item.trim())
            let descAfter = desc[1]?.split(/,/)
            for (let i in descAfter) {
                descIn.push("Dopo cottura: " + descAfter[i].trim())
            }

            const newEntry = {
                name: newEntries[0].name,
                price: newEntries[0].price,
                ingredients: descIn.filter((i) => i !== ""),
                quantity: 1,
                add: [],
                rem: [],
                finalPrice: newEntries[0].price,
                category: newEntries[0].category,
                notes: ''
            }

            // tutto ciò che non è pizza viene aggiunto in fondo all'elenco            
            if (newEntry.category !== ItemCategories.PIZZE) {
                setOrder([...order, newEntry])
            } else {
                setOrder([newEntry, ...order])
            }

            document.getElementById("piatto").value = ""
            setPlates([])
        }
    }
    const handleQuantityIncrease = (index) => {
        const newOrder = [...order]

        newOrder[index].quantity++

        setOrder(newOrder)
    }
    const handleQuantityDecrease = (index) => {
        const newOrder = [...order]

        if (newOrder[index].quantity - 1 > 0) {
            newOrder[index].quantity--
        }

        setOrder(newOrder)
    }
    const handleTrash = (index) => {
        const newOrder = [...order]

        newOrder.splice(index, 1)

        setOrder(newOrder)
    }
    const onChangeTotal = (e) => {
        // settaggio manuale del nuovo totale
        const newTotal = e.target.value
        setSupplement(newTotal - total)
        setFinalTotal(newTotal)
    }

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    // MODIFICA ORDINE
    const [currentPlateIndex, setCurrentPlateIndex] = useState(0)
    const [currentPlate, setCurrentPlate] = useState(null)
    const [additions, setAdditions] = useState([])
    const [removals, setRemovals] = useState([])
    const [plateNotes, setPlateNotes] = useState("")

    const onClickCurrentPlate = (index) => {
        setCurrentPlateIndex(index)

        //carico eventuali precedenti modifiche
        setCurrentPlate(order[index].ingredients)
        setAdditions(order[index].add)
        setRemovals(order[index].rem)
        setPlateNotes(order[index].notes)

        setTimeout(() => { 
            document.getElementById("aggiunte").focus()
        }, 500)
    }
    const onChangeAdditionField = () => {
        // mostra o nasconde le opzioni in base all'input
        currentFocusAdditionField.current = -1
        const entry = document.getElementById("aggiunte").value.toLowerCase()

        const plates = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()

            if (m.category === ItemCategories.INGREDIENTI) {  // considero solo gli ingredienti e non le pizze/bibite omonime
                return nameLower.includes(entry)
            } else {
                return false
            }
        })
        setPlates(plates)
        if (plates.length === 1) {
            addIngredient(entry)
        }
    }
    const onChangePlateNotes = (e) => {
        const notes = e.target.value
        setPlateNotes(notes)
    }
    const currentFocusAdditionField = useRef(-1)
    const onFocusAdditionField = () => {
        if (document.getElementById("listaingredienti"))
            document.getElementById("listaingredienti").style.display = "block"
        if (document.getElementById("aggiunte"))
            document.getElementById("aggiunte").style.borderRadius = ".35rem .35rem 0 0"
    }
    const onBlurAdditionField = () => {
        if (document.getElementById("listaingredienti"))
            document.getElementById("listaingredienti").style.display = "none"
        if (document.getElementById("aggiunte"))
            document.getElementById("aggiunte").style.borderRadius = ".35rem"
    }
    const onKeyDownAdditionField = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("listaingredienti")) {
            if (e.keyCode === 40) {
                currentFocusAdditionField.current += 1
                addActiveAdditionField(document.getElementById("listaingredienti").options)
            } else if (e.keyCode === 38) {
                currentFocusAdditionField.current -= 1
                addActiveAdditionField(document.getElementById("listaingredienti").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocusAdditionField.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("listaingredienti").options) {
                        document.getElementById("listaingredienti").options[currentFocusAdditionField.current]?.click()
                        onBlurAdditionField()
                        removeActiveAdditionField(document.getElementById("listaingredienti").options)
                        currentFocusAdditionField.current = -1
                    }
                }
            }
        }
    }
    function addActiveAdditionField(x) {
        if (!x) return false
        removeActiveAdditionField(x)
        if (currentFocusAdditionField.current >= x.length) currentFocusAdditionField.current = 0
        if (currentFocusAdditionField.current < 0) currentFocusAdditionField.current = (x.length - 1)
        const id = x[currentFocusAdditionField.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActiveAdditionField(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }
    const addIngredient = (additionField) => {
        //find ingrediente tramite nome
        const ingredients = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()
            if (m.category === ItemCategories.INGREDIENTI) {  // considero solo gli ingredienti e non le pizze/bibite omonime
                return nameLower === additionField
            } else {
                return false
            }
        })

        if (ingredients.length > 0) {
            //tengo traccia delle aggiunte
            const newAdditions = [...additions, { name: ingredients[0].name, price: ingredients[0].price }]
            setAdditions(newAdditions)

            //aggiungo l'ingrediente alla visualizzazione
            const newCurrentPlate = [...currentPlate, ingredients[0].name]
            setCurrentPlate(newCurrentPlate)

            document.getElementById("aggiunte").value = ""
            setPlates([])
        }
    }
    const removeIngredient = (index, decrease = false) => {
        let ingredients = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()
            if (m.category === ItemCategories.INGREDIENTI) {  //considero solo gli ingredienti e non le pizze/bibite omonime
                return nameLower === currentPlate[index].toLowerCase()
            } else {
                return false
            }
        })
        if (ingredients.length === 0) {
            ingredients = memoizedMenu.filter((m) => {
                const nameLower = m.name.toLowerCase()
                if (m.category === ItemCategories.INGREDIENTI) {  //considero solo gli ingredienti e non le pizze/bibite omonime
                    return nameLower.includes(currentPlate[index].toLowerCase())
                } else {
                    return false
                }
            })
        }
        if (ingredients.length === 0) {
            ingredients = memoizedMenu.filter((m) => {
                const nameLower = m.name.toLowerCase()
                if (m.category === ItemCategories.INGREDIENTI) {  //considero solo gli ingredienti e non le pizze/bibite omonime
                    return currentPlate[index].toLowerCase().includes(nameLower)
                } else {
                    return false
                }
            })
        }

        if (ingredients.length > 0) {  // ho trovato l'ingrediente (e quindi ho il suo prezzo se devo decrementare)
            //tengo traccia delle rimozioni
            const newRemovals = [...removals, { name: ingredients[0].name, price: decrease ? ingredients[0].price : 0.0 }]
            setRemovals(newRemovals)

            //rimuovo l'ingrediente dalla visualizzazione
            const newCurrentPlate = [...currentPlate]
            newCurrentPlate.splice(index, 1)
            setCurrentPlate(newCurrentPlate)
        } else if (!decrease && ingredients.length === 0) {  // non ho trovato l'ingrediente ma non devo decrementare: tolgo dall'elenco e non cambio il prezzo
            //tengo traccia delle rimozioni
            const newRemovals = [...removals, { name: currentPlate[index], price: 0.0 }]
            setRemovals(newRemovals)

            //rimuovo l'ingrediente dalla visualizzazione
            const newCurrentPlate = [...currentPlate]
            newCurrentPlate.splice(index, 1)
            setCurrentPlate(newCurrentPlate)
        } else {
            console.log("Impossibile rimuovere questo ingrediente")
        }
    }
    const undoAddIngredient = (index, ingName) => {
        const newAdditions = additions.filter((value) => {
            return value.name !== ingName
        })
        setAdditions(newAdditions)

        //rimuovo l'ingrediente dalla visualizzazione
        const newCurrentPlate = [...currentPlate]
        newCurrentPlate.splice(index, 1)
        setCurrentPlate(newCurrentPlate)
    }
    const undoRemoveIngredient = (ingName) => {
        const newRemovals = removals.filter((value) => {
            return value.name !== ingName
        })
        setRemovals(newRemovals)

        //aggiungo l'ingrediente alla visualizzazione
        const newCurrentPlate = [...currentPlate]
        newCurrentPlate.splice(newCurrentPlate.length, 0, ingName)
        setCurrentPlate(newCurrentPlate)
    }
    const handleSave = () => {
        const newOrder = [...order]

        newOrder[currentPlateIndex].ingredients = currentPlate
        newOrder[currentPlateIndex].add = additions
        newOrder[currentPlateIndex].rem = removals
        newOrder[currentPlateIndex].notes = plateNotes
        newOrder[currentPlateIndex].finalPrice = newPrice(newOrder[currentPlateIndex])

        setOrder(newOrder)
        document.getElementById("aggiunte").value = ""
        setPlateNotes("")
        setCurrentPlateIndex(0)
        setCurrentPlate(null)

        setTimeout(() => { 
            document.getElementById("piatto").focus()
        }, 1000)
    }

    // SALVATAGGIO E STAMPA ORDINE
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    async function calculateZipCode() {
        let cap = ''
        if (address) {
            const result = await getGeocode({ address: address })
                // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
                // by setting the second parameter to "true"
                .then((results) => getZipCode(results[0], false))
                .then((zipCode) => {
                    setZipCode(zipCode)
                    return zipCode
                })
                .catch((error) => {
                    console.log(error)
                    return ''
                })
            return result
        }
        return cap
    }
    async function clientInsertOrUpdate(username, phone, nome, address, cap, notes) {
        // inserimento o aggiornamento anagrafica + recupero data inserimento
        const reg = await Services.registryInsert(username, phone, nome, address, cap, notes)
            .then((response) => {
                /* retrieveClients().then(() => {
                    console.log("clienti memorizzati")
                }, (err) => console.log(err)) */
                props.setTriggerClientsUpdate(Date.now())

                return [response.data.client, response.data.numOrders]
            })
            .catch((error) => {
                console.log(error.response?.data?.error)
                return null
            })

        return reg
    }

    async function insertOrder() {
        if (props.user?.username && hour && platform && payment && (phone || nome)) { // o nome o numero: se una persona viene a ordinare in negozio non lascia il suo numero
            if (!takeAway && !address) {
                // TODO meglio un'altra set show per evitare nome in rosso?
                setBody("Inserire un indirizzo.")
                setShow(true)
                return false
            }
            let addr = address

            /* if (!distance) { // se la distanza è ancora vuota bisogna prima calcolarla
                await calculateDistance().then(() => {
                    console.log("1. Distanza: ", distance)
                }, (err) => console.log(err))
            } */

            await calculateZipCode().then((zipCode) => {
                setProgress(25)

                console.log("1. ZIP Code: ", zipCode)
                return zipCode
            })
            .then((zipCode) => {
                setProgress(50)

                if (phone || addr) { // salvo/aggiorno il cliente solo se c'è un telefono, un indirizzo o entrambi
                    const result = clientInsertOrUpdate(props.user.username, phone, nome, addr, zipCode, notes).then((data) => {
                        console.log("2. Cliente: ", data)
                        return data
                    })
                    .catch((error) => {
                        console.error(error)
                        return null
                    })

                    return result
                } else {
                    return null
                }
            })
            .then((data) => {
                setProgress(75)

                let reg = {
                    phone: phone,
                    name: nome,
                    address: addr,
                    notes: notes,
                    cap: zipCode,
                    distance: distance ?? { space: '', time: '' }
                }
                if (data) {
                    const newReg = data[0]
                    /* reg = {
                        phone: newReg.phone,
                        name: newReg.name,
                        address: newReg.address,
                        notes: newReg.notes,
                        cap: newReg.cap ? newReg.cap : '',
                        insertDate: newReg.insertDate ? newReg.insertDate : null,
                        distance: newReg.distance
                    } */
                    reg = newReg
                }

                const mode = takeAway ? OrderConstants.MODE_RITIRO : OrderConstants.MODE_CONSEGNA
                const finalTot = String(finalTotal).replace(',', '.')

                //console.log(props.user.username, reg, date, hour, mode, order, finalTotal, payment, platform)
                const result = Services.insertOrder(props.user.username, reg, date, hour, mode, order, finalTot, payment, platform)
                    .then((response) => {
                        console.log("3. Ordine inserito.")
                        return [response.data, data ? data[1] : null]
                    })
                    .catch((error) => {
                        setBody("Ordine non inserito.")
                        setShow(true)
                        return null
                    })
                return result
            })
            .then((result) => {
                setProgress(100)

                // stampa dell'ordine
                if (result) {
                    const orderData = result[0]
                    const printed = props.print(orderData.order, result[1]).then(() => {
                        console.log("4. Stampato")
                        return true
                    }, (err) => {
                        console.log(err)
                        return false
                    })

                    // se è stato comunicato un coupon, scarico il cliente nella collezione App
                    const coupons = order.filter((i) => Object.values(CouponTypologies).includes(i.category)).map((i) => [i.name, new Date().toJSON()])
                    if (coupons.length > 0) {
                        Services.savePromoInfo(props.user.username, phone, nome, address, Object.fromEntries(coupons))
                        .then((response) => {
                            console.log(response.data)
                        })
                        .catch((error) => {
                            console.log(error.response?.data?.error)
                        })
                    }

                    if (printed) {
                        resetFields()
                        // retrieveFunction()
                        setTriggerOrdersUpdate(Date.now())
                    }
                }
            })
            .catch(error => console.error(error))
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
            return false
        }
    }
    const resetFields = () => {
        setPhone("")
        setNome("")
        setHour("")

        setTakeAway(false)
        setDate(moment().format('YYYY-MM-DD'))
        setPlatform(OrderConstants.NO_PLATFORM)
        setAddress("")
        setZipCode("")
        setNotes("")
        setDistance(null)
        setPayment("Contanti")
        setCoupon(null)
        setCouponName("")

        document.getElementById("piatto").value = ""
        setPlates([])
        setOrder([])
        setTotal(0.0)
        setSupplement(0.0)
        setFinalTotal(0.0)

        setCurrentPlateIndex(0)
        setCurrentPlate(null)
        setAdditions([])
        setRemovals([])
        document.getElementById("aggiunte").value = ""
        setPlateNotes("")

        setShow(false)
        setShowWarning(false)

        document.getElementById("phone").focus()
    }

    // MODIFICA DI UN ORDINE GIÀ SALVATO (VARIABILE CONDIVISA TRA MAIN E ORDERS)
    useEffect(() => {
        console.log(props.orderData)

        if (props.orderData) {
            setPhone(props.orderData.reg.phone)
            setNome(props.orderData.reg.name)
            setHour(props.orderData.hour.split('T')[1].substr(0, 5))

            setTakeAway(props.orderData.mode === OrderConstants.MODE_RITIRO)
            setDate(props.orderData.hour.split('T')[0])
            setPlatform(props.orderData.platform)
            setAddress(props.orderData.reg.address)
            setZipCode(props.orderData.reg.cap ?? '')
            setNotes(props.orderData.reg.notes)
            setDistance(props.orderData.reg.distance)
            setPayment(props.orderData.payment)

            setOrder(props.orderData.items)
            let total = 0.0
            for (let i in props.orderData.items) {
                total += props.orderData.items[i].finalPrice * props.orderData.items[i].quantity
            }
            setTotal(total.toFixed(2))  // calcolato sui piatti
            setFinalTotal(props.orderData.total)  // preso dal campo total
            setSupplement(props.orderData.total - total)  // incluse spese di consegna

            setShow(false)
        } else {
            resetFields()
        }
    }, [props.orderData])

    async function updateOrder(print = false) {
        if (props.user?.username && hour && platform && payment && (phone || nome)) {
            if (!takeAway && !address) {
                // TODO meglio un'altra set show per evitare nome in rosso?
                setBody("Inserire un indirizzo.")
                setShow(true)
                return false
            }
            let addr = address

            await calculateZipCode().then((zipCode) => {
                setProgress(25)

                console.log("1. ZIP Code: ", zipCode)
                return zipCode
            })
            .then((zipCode) => {
                setProgress(50)

                if (phone || addr) { // salvo/aggiorno il cliente solo se c'è un telefono, un indirizzo o entrambi
                    const result = clientInsertOrUpdate(props.user.username, phone, nome, addr, zipCode, notes).then((data) => {
                        console.log("2. Cliente: ", data)
                        return data
                    })
                    .catch((error) => {
                        console.error(error)
                        return null
                    })

                    return result
                } else {
                    return null
                }
            })
            .then((data) => {
                setProgress(75)

                let reg = {
                    phone: phone,
                    name: nome,
                    address: addr,
                    notes: notes,
                    cap: zipCode,
                    distance: distance ?? { space: '', time: '' }
                }
                if (data) {
                    const newReg = data[0]
                    /* reg = {
                        phone: newReg.phone,
                        name: newReg.name,
                        address: newReg.address,
                        notes: newReg.notes,
                        cap: newReg.cap ? newReg.cap : '',
                        insertDate: newReg.insertDate ? newReg.insertDate : null,
                        distance: newReg.distance
                    } */
                    reg = newReg
                }

                const mode = takeAway ? OrderConstants.MODE_RITIRO : OrderConstants.MODE_CONSEGNA
                const finalTot = String(finalTotal).replace(',', '.')

                //console.log(props.orderData['_id'], props.user.username, reg, date, hour, mode, order, finalTotal, payment, platform)
                const result = Services.orderUpdate(props.orderData['_id'], props.user.username, reg, date, hour, mode, order, finalTot, payment, platform)
                    .then((response) => {
                        console.log("3. Ordine aggiornato.")
                        return response.data
                    })
                    .catch((error) => {
                        setBody("Ordine non aggiornato.")
                        setShow(true)
                        return null
                    })
                return result
            })
            .then(async (result) => {
                setProgress(100)

                // eventuale stampa dell'ordine
                if (result) {
                    if (print) {
                        const printed = props.print(result.order).then(() => {
                            console.log("4. Stampato")
                            return true
                        }, (err) => {
                            console.log(err)
                            return false
                        })

                        // se è stato comunicato un coupon, scarico il cliente nella collezione App
                        const coupons = order.filter((i) => Object.values(CouponTypologies).includes(i.category)).map((i) => [i.name, new Date().toJSON()])
                        if (coupons.length > 0) {
                            Services.savePromoInfo(props.user.username, phone, nome, address, Object.fromEntries(coupons))
                            .then((response) => {
                                console.log(response.data)
                            })
                            .catch((error) => {
                                console.log(error.response?.data?.error)
                            })
                        }

                        if (printed) {
                            resetFields()
                            // retrieveFunction()
                            setTriggerOrdersUpdate(Date.now())

                            props.setOrderData(null)
                            props.history.push('/orders')
                        }
                    } else {
                        resetFields()
                        // retrieveFunction()
                        setTriggerOrdersUpdate(Date.now())

                        props.setOrderData(null)
                        props.history.push('/orders')
                    }
                }
            })
            .catch(error => console.error(error))
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
            return false
        }
    }

    //AUTOCOMPLETE GOOGLE MAPS
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: ["address"]
        },
        debounce: 500
    })
    const currentFocusAddress = useRef(-1)
    const onFocusAddress = () => {
        if (document.getElementById("address"))
            document.getElementById("address").style.display = "block"
        if (document.getElementById("indirizzo"))
            document.getElementById("indirizzo").style.borderRadius = ".35rem 0 0 0"
    }
    const onBlurAddress = () => {
        if (document.getElementById("address"))
            document.getElementById("address").style.display = "none"
        if (document.getElementById("indirizzo"))
            document.getElementById("indirizzo").style.borderRadius = ".35rem 0 0 .35rem"
    }
    const onKeyDownAddress = (e) => {
        // gestione frecce e invio sulle opzioni
        if (document.getElementById("address")) {
            if (e.keyCode === 40) {
                currentFocusAddress.current += 1
                addActiveAddress(document.getElementById("address").options)
            } else if (e.keyCode === 38) {
                currentFocusAddress.current -= 1
                addActiveAddress(document.getElementById("address").options)
            } else if (e.keyCode === 13) {
                e.preventDefault()
                if (currentFocusAddress.current > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (document.getElementById("address").options) {
                        document.getElementById("address").options[currentFocusAddress.current]?.click()
                        onBlurAddress()
                        removeActiveAddress(document.getElementById("address").options)
                        currentFocusAddress.current = -1
                    }
                }
            }
        }
    }
    function addActiveAddress(x) {
        if (!x) return false
        removeActiveAddress(x)
        if (currentFocusAddress.current >= x.length) currentFocusAddress.current = 0
        if (currentFocusAddress.current < 0) currentFocusAddress.current = (x.length - 1)
        const id = x[currentFocusAddress.current]?.id
        if (id) document.getElementById(id).style.backgroundColor = "lightgrey"
    }
    function removeActiveAddress(x) {
        for (let i = 0; i < x.length; i++) {
            const id = x[i]?.id
            if (id) document.getElementById(id).style.backgroundColor = "white"
        }
    }


    return (
        <div className="container-fluid">
            <LoadingBar
                color="#36b9cc"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />

            <div className="row">

                {/* <!-- CARD INSERIMENTO ANAGRAFICA --> */}
                <div className="col-xl-8 col-lg-7">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Inserimento anagrafica</h6>
                        </div>
                        <div className="card-body text-dark">

                            {/* <!-- INSERIMENTO TELEFONO --> */}
                            <div className="form-group row">
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label htmlFor="phone"><b>Numero di telefono</b></label>
                                    <div className="input-group">
                                        {/* <input
                                            id="phone"
                                            type="tel"
                                            className={(show && !phone) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                            placeholder="Inserisci il numero di telefono"
                                            value={phone}
                                            list="listaclienti"
                                            onInput={onChangePhone}
                                            autoFocus
                                        ></input> */}
                                        <input
                                            autoComplete="off"
                                            role="combobox"
                                            list=""
                                            id="phone"
                                            type="tel"
                                            className={(show && !phone) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                            name="listaclienti"
                                            placeholder="Inserisci il numero di telefono"
                                            onBlur={() => {
                                                setTimeout(() => { 
                                                    onBlurPhone()
                                                }, 500)
                                            }}
                                            onInput={onChangePhone}
                                            onKeyDown={onKeyDownPhone}
                                            value={phone}
                                            autoFocus
                                        ></input>
                                        <datalist id="listaclienti" role="listbox" style={{
                                            position: "absolute",
                                            zIndex: "999",
                                            backgroundColor: "white",
                                            border: "1px solid #d1d3e2",
                                            borderRadius: "0 .35rem .35rem .35rem",
                                            borderTop: "none",
                                            top: "38px",
                                            left: "0",
                                            right: "0",
                                            padding: "5px",
                                            maxHeight: "12.5rem",
                                            overflowY: "auto"
                                        }}>
                                            {filteredClients && filteredClients.map((c, index) => {
                                                const value = `${c.phone} \u2014 ${c.name} \u2014 ${c.address}`
                                                return (
                                                    <option
                                                        key={`phoneclienti${index}`}
                                                        id={`phoneclienti${index}`}
                                                        value={value}
                                                        onClick={() => {
                                                            document.getElementById("phone").value = value
                                                            onChangePhone()
                                                        }}
                                                        style={{
                                                            backgroundColor: "white",
                                                            padding: "4px",
                                                            marginBottom: "1px",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            })}
                                        </datalist>
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={synchronizePhone}
                                                title="Sincronizza numero di telefono"
                                            >
                                                <i className="fas fa-phone" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- INSERIMENTO NOME --> */}
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label htmlFor="nome"><b>Nome</b></label>
                                    {/* <input
                                        id="nome"
                                        type="text"
                                        className={(show && !nome) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        placeholder="Inserisci un nome o il nome sul campanello"
                                        value={nome}
                                        list="listaclienti2"
                                        onInput={onChangeNome}
                                    ></input> */}
                                    <input
                                        autoComplete="off"
                                        role="combobox"
                                        list=""
                                        id="nome"
                                        type="text"
                                        className={(show && !nome) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        name="listaclienti2"
                                        placeholder="Inserisci un nome o il nome sul campanello"
                                        onFocus={onFocusNome}
                                        onBlur={() => {
                                            setTimeout(() => { 
                                                onBlurNome()
                                            }, 500)
                                        }}
                                        onInput={onChangeNome}
                                        onKeyDown={onKeyDownNome}
                                        value={nome}
                                    ></input>
                                    <datalist id="listaclienti2" role="listbox" style={{
                                        position: "absolute",
                                        zIndex: "999",
                                        backgroundColor: "white",
                                        border: "1px solid #d1d3e2",
                                        borderRadius: "0 0 .35rem .35rem",
                                        borderTop: "none",
                                        left: "0.75em",
                                        right: "0.75em",
                                        padding: "5px",
                                        maxHeight: "12.5rem",
                                        overflowY: "auto"
                                    }}>
                                        {filteredClients && filteredClients.map((c, index) => {
                                            const value = `${c.phone} \u2014 ${c.name} \u2014 ${c.address}`
                                            return (
                                                <option
                                                    key={`nomeclienti${index}`}
                                                    id={`nomeclienti${index}`}
                                                    value={value}
                                                    onClick={() => {
                                                        document.getElementById("nome").value = value
                                                        onChangeNome()
                                                    }}
                                                    style={{
                                                        backgroundColor: "white",
                                                        padding: "4px",
                                                        marginBottom: "1px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    {value}
                                                </option>
                                            )
                                        })}
                                    </datalist>
                                </div>

                            </div>

                            {/* <!-- RITIRO O CONSEGNA --> */}
                            <div className="form-group row">

                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label>
                                        <b>Tipologia:</b>
                                        {takeAway ? <span className='text-danger'><b> Ritiro</b></span>
                                                  : <span className='text-success'><b> Consegna</b></span>}
                                    </label>
                                    <form>
                                        <input
                                            className="btn btn-outline-success"
                                            type="button"
                                            value={OrderConstants.MODE_CONSEGNA}
                                            onClick={() => setTakeAway(false)}
                                        ></input>
                                        &nbsp;
                                        <input
                                            className="btn btn-outline-danger"
                                            type="button"
                                            value={OrderConstants.MODE_RITIRO}
                                            onClick={() => setTakeAway(true)}
                                        ></input>
                                    </form>
                                </div>

                                {/* <!-- INSERIMENTO DATA ORDINE --> */}
                                <div className="col-sm-3 mb-3 mb-sm-0">
                                    <label htmlFor="data"><b>Data</b></label>
                                    <input
                                        id="data"
                                        type="date"
                                        className="form-control form-control-user"
                                        placeholder="Inserisci la data"
                                        value={date}
                                        onChange={onChangeDate}
                                    ></input>
                                </div>

                                {/* <!-- INSERIMENTO ORARIO --> */}
                                <div className="col-sm-3 mb-3 mb-sm-0">
                                    <label htmlFor="ora"><b>Orario</b></label>
                                    <select
                                        id="ora"
                                        className={(show && !hour) ? "form-select form-control form-control-user highlight" : "form-select form-control form-control-user"}
                                        value={hour}
                                        onChange={onChangeHour}
                                    >
                                        <option value="">Seleziona orario</option>
                                        <option value="00:00">Subito</option>
                                        {hours.map((h) => {
                                            return (
                                                <option key={h[0]} value={h[0]}>{h[0]} ({h[1]})</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            {/* <!-- Indirizzo *FACOLTATIVO* --> */}
                            <div className="form-group row">
                                <div className="col-sm-12 mb-3 mb-sm-0">
                                    <label htmlFor="indirizzo"><b>Indirizzo</b></label>
                                    <div className="input-group">
                                        {/* <input
                                            id="indirizzo"
                                            type="text"
                                            className={(show && !address && !takeAway) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                            placeholder="Inserisci l'indirizzo"
                                            value={address}
                                            onChange={onChangeAddress}
                                            disabled={!ready || takeAway}
                                            list="listaluoghi"
                                            onSelect={handleSelect}
                                        ></input> */}
                                        <input
                                            autoComplete="off"
                                            role="combobox"
                                            list=""
                                            id="indirizzo"
                                            type="text"
                                            className={(show && !address && !takeAway) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                            name="address"
                                            placeholder="Inserisci l'indirizzo"
                                            onFocus={onFocusAddress}
                                            onBlur={() => {
                                                setTimeout(() => { 
                                                    onBlurAddress()
                                                }, 500)
                                            }}
                                            onInput={onChangeAddress}
                                            onKeyDown={onKeyDownAddress}
                                            value={address}
                                            disabled={!ready || takeAway}
                                        ></input>
                                        {status === 'OK' && (
                                            <datalist id="address" role="listbox" style={{
                                                position: "absolute",
                                                zIndex: "999",
                                                backgroundColor: "white",
                                                border: "1px solid #d1d3e2",
                                                borderRadius: "0 .35rem .35rem .35rem",
                                                borderTop: "none",
                                                top: "38px",
                                                left: "0",
                                                right: "0",
                                                padding: "5px",
                                                maxHeight: "12.5rem",
                                                overflowY: "auto",
                                                display: "block"
                                            }}>
                                                {data.map(({ place_id, description }) => {
                                                    return (
                                                        <option
                                                            key={place_id}
                                                            id={place_id}
                                                            value={description}
                                                            onClick={(e) => {
                                                                document.getElementById("indirizzo").value = description
                                                                // When user selects a place, we can replace the keyword without request data from API
                                                                // description not empty sets the second parameter to "false"
                                                                onChangeAddress(e, description)
                                                            }}
                                                            style={{
                                                                backgroundColor: "white",
                                                                padding: "4px",
                                                                marginBottom: "1px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            {description}
                                                        </option>
                                                    )
                                                })}
                                            </datalist>
                                        )}
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={calculateDistance}
                                                title="Calcola distanza"
                                                disabled={takeAway}
                                            >
                                                <i className="fas fa-map-pin" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Piattaforma, pagamento, coupon --> */}
                            <div className="form-group row">
                                <div className="col-sm-4 mb-3 mb-sm-0">
                                    <label htmlFor="piattaforma"><b>Piattaforma</b></label>
                                    <select
                                        id="piattaforma"
                                        className={(show && !platform) ? "form-select form-control form-control-user highlight" : "form-select form-control form-control-user"}
                                        value={platform}
                                        onChange={onChangePlatform}
                                    >
                                        <option value="">Seleziona piattaforma</option>
                                        <option value={OrderConstants.NO_PLATFORM}>NO</option>
                                        {props.settings?.delivery?.platforms?.map((p, index) => {
                                            return (
                                                <option
                                                    key={`platform${index}`}
                                                    value={p}
                                                >{p}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-sm-4 mb-3 mb-sm-0">
                                    <label htmlFor="pagamento"><b>Pagamento</b></label>
                                    <select
                                        id="pagamento"
                                        className={(show && !payment) ? "form-select form-control form-control-user highlight" : "form-select form-control form-control-user"}
                                        value={payment}
                                        onChange={onChangePayment}
                                    >
                                        <option value="">Seleziona pagamento</option>
                                        <option value="Contanti">Contanti</option>
                                        <option value="Bancomat">Bancomat</option>
                                        <option value="Pagato">Già Pagato</option>
                                    </select>
                                </div>
                                <div className="col-sm-4 mb-3 mb-sm-0">
                                    <label htmlFor="coupon"><b>Coupon</b></label>
                                    {/* <input
                                        id="coupon"
                                        type="text"
                                        className="form-select form-control form-control-user"
                                        placeholder="Applica un coupon"
                                        value={couponName}
                                        list="listacoupon"
                                        onInput={onChangeCoupon}
                                    ></input> */}
                                    <input
                                        autoComplete="off"
                                        role="combobox"
                                        list=""
                                        id="coupon"
                                        type="text"
                                        className="form-control form-control-user"
                                        name="listacoupon"
                                        placeholder="Applica un coupon"
                                        onFocus={onFocusCoupon}
                                        onBlur={() => {
                                            setTimeout(() => { 
                                                onBlurCoupon()
                                            }, 500)
                                        }}
                                        onInput={onChangeCoupon}
                                        onKeyDown={onKeyDownCoupon}
                                        value={couponName}
                                    ></input>
                                    <datalist id="listacoupon" role="listbox" style={{
                                        position: "absolute",
                                        zIndex: "999",
                                        backgroundColor: "white",
                                        border: "1px solid #d1d3e2",
                                        borderRadius: "0 0 .35rem .35rem",
                                        borderTop: "none",
                                        left: "0.75em",
                                        right: "0.75em",
                                        padding: "5px",
                                        maxHeight: "12.5rem",
                                        overflowY: "auto"
                                    }}>
                                        {props.settings?.coupons?.map((c) => {
                                            // mostra o nasconde le opzioni in base all'input
                                            if (c.name.toLowerCase().includes(couponName.toLowerCase())) {
                                                return (
                                                    <option
                                                        key={c.name}
                                                        id={c.name}
                                                        value={c.name}
                                                        onClick={() => {
                                                            document.getElementById("coupon").value = c.name
                                                            onChangeCoupon()
                                                        }}
                                                        style={{
                                                            backgroundColor: "white",
                                                            padding: "4px",
                                                            marginBottom: "1px",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {c.name}
                                                    </option>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </datalist>
                                </div>
                            </div>

                            {/* <!-- Note, legate all'anagrafica *FACOLTATIVO* --> */}
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <label htmlFor="note"><b>Note</b></label>
                                    <input
                                        id="note"
                                        type="text"
                                        className="form-control form-control-user"
                                        placeholder="Inserisci le note"
                                        value={notes}
                                        onChange={onChangeNotes}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- CARD INSERIMENTO PIATTI --> */}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Ordine</h6>
                            </div>
                            <div className="card-body text-dark">

                                <div className="form-group row col-sm-12">
                                    <label
                                        htmlFor="piatto"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Premi Invio per modificare l'ultima pizza appena aggiunta"
                                        style={{ textDecoration: "underline 1px dotted" }}
                                    >
                                        <b>Nome del piatto</b>
                                    </label>
                                    {/* <input
                                        list="listapiatti"
                                        id="piatto"
                                        type="text"
                                        className="form-control form-control-user"
                                        placeholder="Inserisci il nome del piatto"
                                        value={entry}
                                        onChange={onChangeEntry}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                document.getElementById("modificaRef-0")?.focus()  // focus sull'ultima pizza inserita per modifica veloce (è sempre la prima della lista)
                                            }
                                        }}
                                        onSelect={addEntry}
                                        // autoFocus
                                    ></input> */}
                                    <input
                                        autoComplete="off"
                                        role="combobox"
                                        list=""
                                        id="piatto"
                                        type="text"
                                        className="form-control form-control-user"
                                        name="listapiatti"
                                        placeholder="Inserisci il nome del piatto"
                                        onFocus={onFocusEntry}
                                        onBlur={() => {
                                            setTimeout(() => { 
                                                onBlurEntry()
                                            }, 500)
                                        }}
                                        onInput={onChangeEntry}
                                        onKeyDown={onKeyDownEntry}
                                    ></input>
                                    <datalist id="listapiatti" role="listbox" style={{
                                        position: "absolute",
                                        zIndex: "999",
                                        backgroundColor: "white",
                                        border: "1px solid #d1d3e2",
                                        borderRadius: "0 0 .35rem .35rem",
                                        borderTop: "none",
                                        top: "70px",
                                        left: "0.75em",
                                        right: "0.75em",
                                        padding: "5px",
                                        maxHeight: "12.5rem",
                                        overflowY: "auto"
                                    }}>
                                        {plates.map((p, index) => {
                                            if (p.category !== ItemCategories.INGREDIENTI) {  // mostro solo le pizze/bibite
                                                return (
                                                    <option
                                                        key={`listapiatti${index}`}
                                                        id={`listapiatti${index}`}
                                                        value={p.name}
                                                        onClick={() => {
                                                            document.getElementById("piatto").value = ""
                                                            addEntry(p.name.toLowerCase())
                                                            document.getElementById("piatto")?.focus()
                                                            setTimeout(() => { 
                                                                onFocusEntry()
                                                            }, 500)
                                                        }}
                                                        style={{
                                                            backgroundColor: "white",
                                                            padding: "4px",
                                                            marginBottom: "1px",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {p.name}
                                                    </option>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </datalist>
                                </div>

                                {/* <!-- HEADING IMPORTANTE: L'ultimo piatto aggiunto deve essere il primo della lista --> */}
                                <div className="table-responsive row col-sm-12">
                                    <table className="table text-dark">
                                        <thead>
                                            <tr>
                                                <th>Quantità</th>
                                                <th>Piatto</th>
                                                <th>Prezzo</th>
                                                <th>Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.map((o, index) => {
                                                if (Object.values(CouponTypologies).includes(o.category)) {
                                                    return (
                                                        <tr key={`tabellaordine${index}`}>
                                                            <td>
                                                                <button type="button" className="btn btn-primary btn-circle btn-sm" disabled>
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                                <span> {o.quantity} </span>
                                                                <button type="button" className="btn btn-primary btn-circle btn-sm" disabled>
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </td>
                                                            <td>{o.name}</td>
                                                            <td>€ {o.finalPrice.toFixed(2)}</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-warning btn-icon"
                                                                    disabled
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-icon"
                                                                    data-toggle="modal"
                                                                    data-target="#modalCancellaRecord"
                                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                                    onClick={() => setCurrentPlateIndex(index)}
                                                                    title="Cancella"
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                } else if (o.category === OrderConstants.DELIVERY_COSTS) {
                                                    return null
                                                } else {
                                                    return (
                                                        <tr key={`tabellaordine${index}`}>
                                                            <td>
                                                                <button type="button" className="btn btn-primary btn-circle btn-sm" onClick={() => handleQuantityDecrease(index)}>
                                                                    <i className="fas fa-minus"></i>
                                                                </button>
                                                                {/* TODO: campo input a mano al posto di span? */}
                                                                <span> {o.quantity} </span>
                                                                <button type="button" className="btn btn-primary btn-circle btn-sm" onClick={() => handleQuantityIncrease(index)}>
                                                                    <i className="fas fa-plus"></i>
                                                                </button>
                                                            </td>
                                                            <td>{(o.add.length === 0 && o.rem.length === 0) ? o.name : o.name + '*'}</td>
                                                            <td>€ {o.finalPrice.toFixed(2)}</td>
                                                            <td>
                                                                <button
                                                                    id={`modificaRef-${index}`}
                                                                    type="button"
                                                                    className="btn btn-warning btn-icon"
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModal"
                                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                                    onClick={() => onClickCurrentPlate(index)}
                                                                    title="Modifica"
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-icon"
                                                                    data-toggle="modal"
                                                                    data-target="#modalCancellaRecord"
                                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                                    onClick={() => setCurrentPlateIndex(index)}
                                                                    title="Cancella"
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-5">

                    <div className="card shadow mb-4">
                        {/* <!-- Card Body --> */}
                        <div className="card-body text-dark">
                            <button
                                className="btn btn-success btn-user btn-block"
                                type="button"
                                data-toggle="modal"
                                data-target="#viewProgrammazioneOrari"
                            >
                                <i className="fas fa-clock"></i>
                                <span className="ml-2">Verifica orari</span>
                            </button>
                            <button
                                className="btn btn-warning btn-user btn-block"
                                type="button"
                                data-toggle="modal"
                                data-target="#viewProgrammazioneConsegne"
                                onClick={() => {
                                    if (address) {
                                        Services.calculateCoordinates(address)
                                                .then((response) => {
                                                    console.log(response.data)
                                                    if (response.data.status === 'OK') {
                                                        setCurrentCoordinates({
                                                            lat: response.data.results[0].geometry.location.lat,
                                                            lng: response.data.results[0].geometry.location.lng
                                                        })
                                                    } else {
                                                        setCurrentCoordinates(null)
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error.response?.data?.error)
                                                })
                                    }
                                }}
                            >
                                <i className="fas fa-map-marked-alt"></i>
                                <span className="ml-2">Verifica distanze</span>
                            </button>
                        </div>
                    </div>


                    <div className="card shadow mb-4">
                        {/* <!-- Card Header --> */}
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex justify-content-left">
                                    <h6 className="m-0 font-weight-bold text-primary">Riepilogo</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <button className="btn btn-danger btn-user btn-sm" type="button" onClick={resetFields} disabled={props.orderData}>
                                        Pulisci
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="card-body text-dark">
                            {show ? (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Attenzione!</strong> {body}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <SummaryContainer
                                currentOrder={{
                                    reg: {
                                        phone: phone,
                                        name: nome,
                                        address: address,
                                        distance: distance,
                                        notes: notes
                                    },
                                    mode: takeAway ? OrderConstants.MODE_RITIRO : OrderConstants.MODE_CONSEGNA,
                                    hour: hour,
                                    platform: platform,
                                    payment: payment,
                                    items: order,
                                    total: finalTotal  // triggera supplement
                                }}
                                includeTotal={false}
                            ></SummaryContainer>
                            {Number(supplement.toFixed(2)) !== 0 &&
                                <>Supplemento: € {supplement.toFixed(2)}<br /></>
                            }
                            <br /><br />

                            <div className="row">
                                <div className="input-group col-12">
                                    <div className="input-group-prepend">
                                        <h2>Totale: € &nbsp;</h2>
                                    </div>
                                    <input
                                        type="number"
                                        id="customTotal"
                                        className="form-control form-control-user"
                                        min="0"
                                        step="0.1"
                                        value={finalTotal}
                                        onChange={onChangeTotal}
                                    ></input>
                                </div>
                            </div>
                            <br />

                            {props.orderData ? ((props.orderData.platform === OrderConstants.APP_PLATFORM && props.orderData?.status === "In attesa") ? <button
                                className="btn btn-primary btn-user btn-block"
                                type="button"
                                onClick={() => {
                                    const newOrderData = {
                                        reg: {
                                            phone: phone,
                                            name: nome,
                                            address: address,
                                            distance: distance,
                                            notes: notes
                                        },
                                        mode: takeAway ? OrderConstants.MODE_RITIRO : OrderConstants.MODE_CONSEGNA,
                                        hour: hour,
                                        platform: platform,
                                        payment: payment,
                                        items: order,
                                        total: finalTotal
                                    }
                                    props.appOrdersConfirm(newOrderData, props.orderData)
                                    resetFields()
                                    props.setOrderData(null)
                                }}
                            >
                                Conferma e stampa comanda
                            </button> : <>
                                <button
                                    className="btn btn-primary btn-user btn-block"
                                    type="button"
                                    onClick={() => updateOrder(true)}
                                >
                                    Salva e stampa comanda
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-primary btn-user btn-block"
                                    type="button"
                                    onClick={() => updateOrder()}
                                >
                                    Salva
                                </button>
                            </>) : <button
                                className="btn btn-primary btn-user btn-block"
                                type="button"
                                onClick={() => insertOrder()}
                            >
                                Stampa comanda
                            </button>}
                            {showWarning ? (
                                <div className="alert alert-warning alert-dismissible fade show mb-0 mt-3" role="alert">
                                    <strong>Attenzione!</strong> <ul>{bodyWarning.map((w, i) => {
                                        return (
                                            <li key={`warning${i}`}>{w}</li>
                                        )
                                    })}</ul>
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowWarning(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal per aggiunte o rimozioni --> */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifica Pizza</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    document.getElementById("aggiunte").value = ""
                                    setPlateNotes("")
                                    setCurrentPlateIndex(0)
                                    setCurrentPlate(null)
                                    setTimeout(() => { 
                                        document.getElementById("piatto").focus()
                                    }, 1000)
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        {/* <input
                                            list="listaingredienti"
                                            id="aggiunte"
                                            type="text"
                                            className="form-control form-control-user"
                                            placeholder="Inserisci l'aggiunta"
                                            value={additionField}
                                            onChange={onChangeAdditionField}
                                            onSelect={addIngredient}
                                            disabled={!(currentPlate && currentPlate.length > 0)}
                                        ></input> */}
                                        <input
                                            autoComplete="off"
                                            role="combobox"
                                            list=""
                                            id="aggiunte"
                                            type="text"
                                            className="form-control form-control-user"
                                            name="listaingredienti"
                                            placeholder="Inserisci l'aggiunta"
                                            onFocus={onFocusAdditionField}
                                            onBlur={() => {
                                                setTimeout(() => { 
                                                    onBlurAdditionField()
                                                }, 500)
                                            }}
                                            onInput={onChangeAdditionField}
                                            onKeyDown={onKeyDownAdditionField}
                                            disabled={!(currentPlate && currentPlate.length > 0)}
                                        ></input>
                                        <br />
                                        <input
                                            id="notepiatto"
                                            type="text"
                                            className="form-control form-control-user"
                                            placeholder="Note"
                                            value={plateNotes}
                                            onChange={onChangePlateNotes}
                                        ></input>
                                        <datalist id="listaingredienti" role="listbox" style={{
                                            position: "absolute",
                                            zIndex: "999",
                                            backgroundColor: "white",
                                            border: "1px solid #d1d3e2",
                                            borderRadius: "0 0 .35rem .35rem",
                                            borderTop: "none",
                                            top: "38px",
                                            left: "0.75em",
                                            right: "0.75em",
                                            padding: "5px",
                                            maxHeight: "12.5rem",
                                            overflowY: "auto"
                                        }}>
                                            {plates.map((p, index) => {
                                                if (p.category === ItemCategories.INGREDIENTI) {  // mostro solo gli ingredienti
                                                    return (
                                                        <option
                                                            key={`listaingredienti${index}`}
                                                            id={`listaingredienti${index}`}
                                                            value={p.name}
                                                            onClick={() => {
                                                                document.getElementById("aggiunte").value = ""
                                                                addIngredient(p.name.toLowerCase())
                                                                document.getElementById("aggiunte")?.focus()
                                                                setTimeout(() => { 
                                                                    onFocusAdditionField()
                                                                }, 500)
                                                            }}
                                                            style={{
                                                                backgroundColor: "white",
                                                                padding: "4px",
                                                                marginBottom: "1px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            {p.name}
                                                        </option>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                        </datalist>
                                    </div>
                                </div>
                            </div>

                            {currentPlate ? (currentPlate.map((i, index) => {
                                const isAdd = additions.filter((value) => {
                                    return value.name === i
                                }).length > 0

                                return (
                                    <div className="row" key={`ingredienti-${index}`}>
                                        {isAdd ? (
                                            <>
                                                <div className="col-8">
                                                    Aggiunta: <span style={{ textTransform: 'capitalize' }}>{i}</span>
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-circle btn-sm"
                                                        onClick={() => undoAddIngredient(index, i)}
                                                        title="Annulla aggiunta"
                                                    >
                                                        <i className="fas fa-undo"></i>
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-8">
                                                    <span style={{ textTransform: 'capitalize' }}>{i}</span>
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn-circle btn-sm"
                                                        onClick={() => removeIngredient(index)}
                                                        title="Cancella"
                                                    >
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-circle btn-sm"
                                                        onClick={() => removeIngredient(index, true)}
                                                        title="Rimuovi"
                                                    >
                                                        <i className="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )
                            })) : (
                                <div></div>
                            )}
                            {removals ? (removals.map((i, index) => {
                                return (
                                    <div className="row" key={`rimozioni-${index}`}>
                                        <div className="col-8">
                                            Senza: <span style={{ textTransform: 'capitalize' }}>{i.name}</span>
                                        </div>
                                        <div className="col-4">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-circle btn-sm"
                                                onClick={() => undoRemoveIngredient(i.name)}
                                                title="Annulla rimozione"
                                            >
                                                <i className="fas fa-trash-restore-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })) : (
                                <div></div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    document.getElementById("aggiunte").value = ""
                                    setPlateNotes("")
                                    setCurrentPlateIndex(0)
                                    setCurrentPlate(null)
                                    setTimeout(() => { 
                                        document.getElementById("piatto").focus()
                                    }, 1000)
                                }}
                            >
                                Chiudi
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={handleSave}
                            >
                                Salva
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella record</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentPlateIndex(0)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare il record selezionato?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentPlateIndex(0)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => { handleTrash(currentPlateIndex); setCurrentPlateIndex(0) }}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA RECORD --> */}

            {/* <!-- Modale per tabella ordini giornalieri --> */}
            <div className="modal fade" id="viewProgrammazioneOrari" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Tabella ordini per orari</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="table-responsive">
                                {renderVerify()}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per mappa consegne --> */}
            <div className="modal fade" id="viewProgrammazioneConsegne" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Mappa delle consegne</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            {props.settings?.general?.coordinates ? <MapContainer array={orders} current={currentCoordinates} settings={props.settings} />
                            : <div className="alert alert-danger" role="alert">
                                <strong>Attenzione!</strong> Inserisci l'indirizzo della pizzeria nelle Impostazioni generali per visualizzare la mappa.
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Main
