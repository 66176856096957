import React, { useEffect, useState } from 'react'

import Services from '../services.js'
import EmployeeTypologies from '../enums/EmployeeTypologies'


const SettingsEmployees = (props) => {

    const [employees, setEmployees] = useState([])

    async function retrieveEmployees() {
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setEmployees(response.data.settings?.employees ?? [])
                })
                .catch((error) => {
                    console.log(error)
                    setEmployees([])
                })
        } else {
            setEmployees([])
        }
    }

    useEffect(() => {
        retrieveEmployees().then(() => {
            console.log("dipendenti recuperati")
        }, (err) => console.log(err))
    }, [])

    const [nome, setNome] = useState("")
    const [pay, setPay] = useState("")
    const [repayment, setRepayment] = useState("")
    const [fiscalCode, setFiscalCode] = useState("")
    const [codComunicazione, setCodComunicazione] = useState("")
    const [typology, setTypology] = useState("")
    const [currentEntry, setCurrentEntry] = useState(null)
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    const onChangeNome = (e) => {
        const nome = e.target.value
        setNome(nome)
    }
    const onChangePay = (e) => {
        const pay = e.target.value
        setPay(pay)
    }
    const onChangeRepayment = (e) => {
        const repayment = e.target.value
        setRepayment(repayment)
    }
    const onChangeFiscalCode = (e) => {
        const fiscalCode = e.target.value
        setFiscalCode(fiscalCode)
    }
    const onChangeCodComunicazione = (e) => {
        const codComunicazione = e.target.value
        setCodComunicazione(codComunicazione)
    }
    const onChangeTypology = (e) => {
        const typology = e.target.value
        setTypology(typology)
    }

    useEffect(() => {
        if (currentEntry) {
            setTypology(currentEntry?.typology ?? "")
            setNome(currentEntry?.name ?? "")
            setPay(currentEntry?.pay >= 0 ? String(currentEntry.pay) : "")
            setRepayment(currentEntry?.repayment >= 0 ? String(currentEntry.repayment) : "")
            setFiscalCode(currentEntry?.fiscalCode ?? "")
            setCodComunicazione(currentEntry?.codComunicazione ?? "")
        } else {
            setTypology("")
            setNome("")
            setPay("")
            setRepayment("")
            setFiscalCode("")
            setCodComunicazione("")
        }
    }, [currentEntry])

    const insertEmployees = () => {
        if (props.user?.username && typology && nome) {
            const employee = {
                typology: typology,
                name: nome,
                pay: Number(pay.replace(',', '.')),
                repayment: Number(repayment.replace(',', '.')),
                fiscalCode: fiscalCode,
                codComunicazione: codComunicazione
            }

            Services.insertEmployees(props.user.username, employee)
                    .then((response) => {
                        setTypology("")
                        setNome("")
                        setPay("")
                        setRepayment("")
                        setFiscalCode("")
                        setCodComunicazione("")
                        retrieveEmployees().then(() => console.log("dipendenti recuperati"), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare/aggiornare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentEntry(null)
    }
    const updateEmployees = () => {
        if (props.user?.username && typology && currentEntry.name && nome) {
            const employee = {
                typology: typology,
                name: nome,
                pay: Number(pay.replace(',', '.')),
                repayment: Number(repayment.replace(',', '.')),
                fiscalCode: fiscalCode,
                codComunicazione: codComunicazione
            }

            Services.updateEmployees(props.user.username, currentEntry.name, employee)
                    .then((response) => {
                        setTypology("")
                        setNome("")
                        setPay("")
                        setRepayment("")
                        setFiscalCode("")
                        setCodComunicazione("")
                        retrieveEmployees().then(() => console.log("dipendenti recuperati"), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare/aggiornare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentEntry(null)
    }
    const updateTypology = (employee) => {
        if (props.user?.username) {
            Services.updateEmployees(props.user.username, employee.name, employee)
                    .then((response) => {
                        retrieveEmployees().then(() => console.log("dipendenti recuperati"), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare/aggiornare il record.")
                        setShow(true)
                    })
        }
    }
    const deleteEmployees = () => {
        if (props.user?.username && currentEntry.name) {
            Services.deleteEmployees(props.user.username, currentEntry.name)
                    .then((response) => {
                        retrieveEmployees().then(() => console.log("dipendenti recuperati"), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile eliminare il record.")
                        setShow(true)
                    })
        }
        setCurrentEntry(null)
    }

    return (

       <div className="container-fluid">

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Elenco Dipendenti</h6>
                </div>

                <div className="card-body text-dark">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-success btn-font-sm"
                                data-toggle="modal"
                                data-target="#modal-gestione"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                <i className="fa fa-plus"></i>
                                <span className="ml-2">Aggiungi</span>
                            </button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table text-dark" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Stipendio orario</th>
                                    <th>Rimborso consegna</th>
                                    <th>Codice Fiscale</th>
                                    <th>Codice Comunicazione</th>
                                    <th>Tipologia</th>
                                    <th style={{ width: "10%" }}>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map((d, index) => {
                                    return (
                                        <tr key={`employee${index}`}>
                                            <td>{d.name}</td>
                                            <td>€ {d.pay >= 0 ? d.pay.toFixed(2) : ""}</td>
                                            <td>€ {d.repayment >= 0 ? d.repayment.toFixed(2) : ""}</td>
                                            <td>{d.fiscalCode}</td>
                                            <td>{d.codComunicazione}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        className="btn btn-primary dropdown-toggle btn-block"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {Object.keys(EmployeeTypologies).find((key) => EmployeeTypologies[key] === d.typology)}
                                                    </button>
                                                    <div className="dropdown-menu animated--fade-in" aria-labelledby="dropdownMenuButton">
                                                        {Object.entries(EmployeeTypologies).map(([k, v]) => {
                                                            return (
                                                                <span
                                                                    key={`employee${index}-${k}`}
                                                                    className="dropdown-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    employeeindex={index}
                                                                    onClick={(e) => {
                                                                        let employee = employees[e.currentTarget.getAttribute('employeeindex')]
                                                                        employee.typology = v
                                                                        updateTypology(employee)
                                                                    }}
                                                                >{k}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#modal-gestione"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    employeeindex={index}
                                                    onClick={(e) => setCurrentEntry(employees[e.currentTarget.getAttribute('employeeindex')])}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#modal-cancella"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    employeeindex={index}
                                                    onClick={(e) => setCurrentEntry(employees[e.currentTarget.getAttribute('employeeindex')])}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA --> */}
            <div className="modal fade" id="modal-cancella" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella Dipendente</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentEntry(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare il Dipendente selezionato?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentEntry(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => deleteEmployees()}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA --> */}

            {/* <!-- INIZIO MODAL GESTIONE --> */}
            <div className="modal fade" id="modal-gestione" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Inserisci / aggiorna Dipendente</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentEntry(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label htmlFor="typology" className="col-form-label col-4 d-flex justify-content-end"><b>Tipologia</b></label>
                                <div className="col-8">
                                    <select
                                        id="typology"
                                        className={(show && !typology) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={typology}
                                        onChange={onChangeTypology}
                                    >
                                        <option value="">Seleziona la tipologia</option>
                                        {Object.entries(EmployeeTypologies).map(([k, v]) => {
                                            return (
                                                <option key={v} value={v}>{k}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-form-label col-4 d-flex justify-content-end"><b>Nome</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="nome"
                                        placeholder="Inserire un nome"
                                        className={(show && !nome) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={nome}
                                        onChange={onChangeNome}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="pay" className="col-form-label col-4 d-flex justify-content-end"><b>Stipendio orario</b></label>
                                <div className="col-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">€</span>
                                        </div>
                                        <input
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            id="pay"
                                            placeholder="Inserire un importo"
                                            className="form-control"
                                            value={pay}
                                            onChange={onChangePay}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="repayment" className="col-form-label col-4 d-flex justify-content-end"><b>Rimborso consegna</b></label>
                                <div className="col-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">€</span>
                                        </div>
                                        <input
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            id="repayment"
                                            placeholder="Inserire un importo"
                                            className="form-control"
                                            value={repayment}
                                            onChange={onChangeRepayment}
                                            disabled={typology !== EmployeeTypologies.FATTORINO}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="cf" className="col-form-label col-4 d-flex justify-content-end"><b>Codice Fiscale</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="cf"
                                        placeholder="Inserire il codice fiscale"
                                        className="form-control"
                                        value={fiscalCode}
                                        onChange={onChangeFiscalCode}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="codCom" className="col-form-label col-4 d-flex justify-content-end"><b>Codice Comunicazione</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="codCom"
                                        placeholder="Inserire il codice comunicazione"
                                        className="form-control"
                                        value={codComunicazione}
                                        onChange={onChangeCodComunicazione}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentEntry(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (currentEntry) {
                                        updateEmployees()
                                    } else {
                                        insertEmployees()
                                    }
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL GESTIONE --> */}

        </div>
    )
}

export default SettingsEmployees
