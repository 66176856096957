import React, { useEffect, useState } from 'react'

import Services from '../services.js'
import CouponTypologies from '../enums/CouponTypologies'
import CouponAutomations from '../enums/CouponAutomations'


const Coupon = (props) => {

    const [coupons, setCoupons] = useState([])
    const [currentCoupon, setCurrentCoupon] = useState(null)
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")
    const [nome, setNome] = useState("")
    const [typology, setTypology] = useState("")
    const [value, setValue] = useState("")
    const [automation, setAutomation] = useState("")

    const onChangeNome = (e) => {
        const nome = e.target.value
        setNome(nome)
    }
    const onChangeTypology = (e) => {
        const typology = e.target.value
        setTypology(typology)
    }
    const onChangeValue = (e) => {
        const value = e.target.value
        setValue(value)
    }
    const onChangeAutomation = (e) => {
        const automation = e.target.value
        setAutomation(automation)
    }

    async function retrieveCoupons() {
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setCoupons(response.data.settings?.coupons ?? [])
                })
                .catch((error) => {
                    console.log(error)
                    setCoupons([])
                })
        } else {
            setCoupons([])
        }
    }

    useEffect(() => {
        retrieveCoupons().then(() => {
            console.log("coupon recuperati")
        }, (err) => console.log(err))
    }, [])

    useEffect(() => {
        if (currentCoupon) {
            setNome(currentCoupon?.name ?? "")
            setTypology(currentCoupon?.typology ?? "")
            setValue(currentCoupon?.value >= 0 ? String(currentCoupon.value) : "")
            setAutomation(currentCoupon.automation ?? "")
        } else {
            setNome("")
            setTypology("")
            setValue("")
            setAutomation("")
        }
    }, [currentCoupon])

    const insertCoupons = () => {
        if (props.user?.username && nome) {
            const coupon = {
                name: nome,
                typology: typology,
                value: Number(value.replace(',', '.'))
            }
            if (automation) {
                coupon.automation = automation
            }

            Services.insertCoupons(props.user.username, coupon)
                    .then((response) => {
                        setNome("")
                        setTypology("")
                        setValue("")
                        setAutomation("")
                        retrieveCoupons().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentCoupon(null)
    }
    const updateCoupons = () => {
        if(props.user?.username && currentCoupon.name && nome) {
            const coupon = {
                name: nome,
                typology: typology,
                value: Number(value.replace(',', '.'))
            }
            if (automation) {
                coupon.automation = automation
            }

            Services.updateCoupons(props.user.username, currentCoupon.name, coupon)
                    .then((response) => {
                        setNome("")
                        setTypology("")
                        setValue("")
                        setAutomation("")
                        retrieveCoupons().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile aggiornare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentCoupon(null)
    }
    const deleteCoupons = () => {
        if (props.user?.username && currentCoupon.name) {
            Services.deleteCoupons(props.user.username, currentCoupon.name)
                    .then((response) => {
                        retrieveCoupons().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile eliminare il record.")
                        setShow(true)
                    })
        }
        setCurrentCoupon(null)
    }

    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Elenco Coupon</h6>
                </div>
                <div className="card-body">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-success btn-font-sm"
                                data-toggle="modal"
                                data-target="#modal-gestione"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                <i className="fa fa-plus"></i>
                                <span className="ml-2">Aggiungi</span>
                            </button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table text-dark">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Tipologia</th>
                                    <th>Valore</th>
                                    <th>Utilizza per</th>
                                    <th style={{ width: "10%" }}>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons.map((t, index) => {
                                    return (
                                        <tr key={`coupon-${index}`}>
                                            <td>{t.name}</td>
                                            <td>{t.typology}</td>
                                            <td>
                                                {t.typology === CouponTypologies.SCONTO && t.value >= 0 ? `-${t.value} %` : ""}
                                                {t.typology === CouponTypologies.DECREMENTO && t.value >= 0 ? `€ -${t.value.toFixed(2)}` : ""}
                                            </td>
                                            <td>{t.automation && Object.entries(CouponAutomations).filter(([k, v]) => k === t.automation)[0][1]}</td>
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    couponindex={index}
                                                    data-toggle="modal"
                                                    data-target="#modal-gestione"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={(e) => {
                                                        setCurrentCoupon(coupons[e.currentTarget.getAttribute('couponindex')])
                                                    }}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    couponindex={index}
                                                    data-toggle="modal"
                                                    data-target="#modalCancellaRecord"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={(e) => {
                                                        setCurrentCoupon(coupons[e.currentTarget.getAttribute('couponindex')])
                                                    }}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per creazione coupon --> */}
            <div className="modal fade" id="modal-gestione" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Inserisci / aggiorna Coupon</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentCoupon(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-form-label col-4 d-flex justify-content-end"><b>Nome</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="nome"
                                        placeholder="Inserire un nome"
                                        className={(show && !nome) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={nome}
                                        onChange={onChangeNome}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="typology" className="col-form-label col-4 d-flex justify-content-end"><b>Tipologia</b></label>
                                <div className="col-8">
                                    <select
                                        id="typology"
                                        className={(show && !typology) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={typology}
                                        onChange={onChangeTypology}
                                    >
                                        <option value="">Seleziona la tipologia</option>
                                        <option value={CouponTypologies.SCONTO}>Sconto percentuale</option>
                                        <option value={CouponTypologies.DECREMENTO}>Decremento di prezzo</option>
                                        <option value={CouponTypologies.OMAGGIO_BIBITE}>Bibita omaggio</option>
                                        <option value={CouponTypologies.OMAGGIO_PIZZE}>Pizza omaggio</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="value" className="col-form-label col-4 d-flex justify-content-end"><b>Valore</b></label>
                                <div className="col-8">
                                    <input
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        disabled={![CouponTypologies.SCONTO, CouponTypologies.DECREMENTO].includes(typology)}
                                        id="value"
                                        placeholder="Inserire un importo"
                                        className="form-control"
                                        value={value}
                                        onChange={onChangeValue}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="automation" className="col-form-label col-4 d-flex justify-content-end"><b>Utilizza per</b></label>
                                <div className="col-8">
                                    <select
                                        id="automation"
                                        className="form-control form-control-user"
                                        value={automation}
                                        onChange={onChangeAutomation}
                                    >
                                        <option value=""></option>
                                        {Object.entries(CouponAutomations).map(([k, v]) => {
                                            return (
                                                <option key={k} value={k}>{v}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentCoupon(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (currentCoupon) {
                                        updateCoupons()
                                    } else {
                                        insertCoupons()
                                    }
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella Coupon</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentCoupon(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare il Coupon selezionato?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentCoupon(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => deleteCoupons()}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Coupon
